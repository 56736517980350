<template>
    <div class="container repair-container" v-if="device">

        <!-- Links -->
        <label>What type of photo are you adding?</label>
        <div class="card-grid grid-col-2 mb-0" :class="[pageTitle === 'After' ? 'grid-col-md-3': 'grid-col-md-4']">
            <div class="card-link-container">
                <input type="file" name="frontPhoto" id="frontPhoto" class="file-input" accept="image/*" capture="environment" @change="compressAndAddPhoto(`${beforeOrAfter}-front`, 'frontPhoto')">
                <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info" for="frontPhoto">
                    <icon-device-front
                            size="md"
                            class="icon-all-white m-0 mr-2">
                    </icon-device-front>
                    Front Photo
                </label>
            </div>
            <div class="card-link-container">
                <input type="file" name="backPhoto" id="backPhoto" class="file-input" accept="image/*" capture="environment" @change="compressAndAddPhoto(`${beforeOrAfter}-back`, 'backPhoto')">
                <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info" for="backPhoto">
                    <icon-device-back
                            size="md"
                            class="icon-all-white m-0 mr-2">
                    </icon-device-back>
                    Back Photo
                </label>
            </div>
            <div class="card-link-container">
                <input type="file" name="otherPhoto" id="otherPhoto" class="file-input" accept="image/*" capture="environment" @change="compressAndAddPhoto(`${beforeOrAfter}-other`, 'otherPhoto')">
                <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info" for="otherPhoto">
                    <icon-camera
                            size="md"
                            class="icon-all-white m-0 mr-2">
                    </icon-camera>
                    Other Photo
                </label>
            </div>
            <div class="card-link-container">
                <input type="file" name="esdResults" id="esdResults" class="file-input" accept="image/*" capture="environment" @change="compressAndAddPhoto(`${beforeOrAfter}-${esdOrOqc}-results`, 'esdResults')">
                <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info" for="esdResults">
                    <icon-checklist
                            size="md"
                            class="icon-all-white m-0 mr-2">
                    </icon-checklist>
                    <template v-if="pageTitle === 'Before'">
                        ESD Results
                    </template>
                    <template v-if="pageTitle === 'After'">
                        OQC Results
                    </template>
                </label>
            </div>
			<div class="card-link-container" v-if="pageTitle === 'After'">
				<input type="file" name="fenrirSvc" id="fenrirSvc" class="file-input" accept="image/*" capture="environment" @change="compressAndAddPhoto(`${beforeOrAfter}-fenrir-svc`, 'fenrirSvc')">
				<label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info" for="fenrirSvc">
					<icon-device-clean
						size="md"
						class="icon-all-white m-0 mr-2">
					</icon-device-clean>
					Fenrir SVC Log
				</label>
			</div>
            <div class="card-link-container" v-if="pageTitle === 'After' && isRefurbOrMailIn">
                <input type="file" name="liveCall" id="liveCall" class="file-input" accept="image/*" capture="environment" @change="compressAndAddPhoto(`${beforeOrAfter}-live-call`, 'liveCall')">
                <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info" for="liveCall">
                    <icon-mobile
                        size="md"
                        class="icon-all-white m-0 mr-2">
                    </icon-mobile>
                    Live Call
                </label>
            </div>
        </div>

        <h3 class="mt-4">Uploaded Photos</h3>

        <!-- Upload test results alert -->
        <template v-if="pageTitle === 'Before' && displayBeforeTestBanner">
            <div class="alert alert-warning">
                <animated-warning></animated-warning>
                <div class="alert-content">
                    Please supply a picture of your ESD test results
                </div>
            </div>
        </template>

		<template v-if="pageTitle === 'After'">
			<template v-if="displayAfterTestBanner">
				<div class="alert alert-warning">
					<animated-warning></animated-warning>
					<div class="alert-content">
						Please supply a picture of your OQC test results
					</div>
				</div>
			</template>

			<template v-if="device.make_code === 'samsung' && displayFenrirSVCBanner">
				<div class="alert alert-warning">
					<animated-warning></animated-warning>
					<div class="alert-content">
						Please supply a picture of your Fenrir SVC log
					</div>
				</div>
			</template>
		</template>

        <!-- Empty state -->
        <div class="card card-body" v-if="!imagePaths.length">
            <empty-state
                    emptyIcon
                    icon="camera"
                    title="No photos uploaded"
                    subtitle="Select one of the options above to add a photo">
            </empty-state>
        </div>

        <!-- List of photos -->
        <template>
            <div class="card-grid grid-col-2 grid-col-md-3">
                    <image-item
                            v-for="(imagePath, key) in imagePaths" :key="key"
                            :imageType="imagePath.type"
                            :imageData="imagePath.path">
                    </image-item>
            </div>
        </template>

        <!-- Footer -->
        <fixed-footer
                v-if="pageTitle === 'Before'"
                :class="{'active': footerActive}"
                :nextLabel="this.nextRepairStep($route.name).title"
                :nextRoute="this.nextRepairStep($route.name).routeName"
                :prevRoute="this.prevRepairStep($route.name).routeName">
        </fixed-footer>
<!--        <fixed-footer-->
<!--                v-if="pageTitle === 'After'"-->
<!--                :class="{'active': showFooter}"-->
<!--                nextLabel="Samsung Notes"-->
<!--                nextRoute="repair.samsungNotes">-->
<!--        </fixed-footer>-->
        <fixed-footer
                v-if="pageTitle === 'After'"
                :class="{'active': footerActive}"
                :nextLabel="this.nextRepairStep($route.name).title"
                :nextRoute="this.nextRepairStep($route.name).routeName"
                :prevRoute="this.prevRepairStep($route.name).routeName">
        </fixed-footer>

        <!-- Image preview modal -->
        <repair-image-modal></repair-image-modal>

    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";

    import {firebase} from '@firebase/app';
    import '@firebase/storage';
    import { v4 as uuidv4 } from 'uuid';
    import Compressor from 'compressorjs';

    export default {

        props: ['jobId', 'deviceId'],

        data() {
            return {
                displayAfterTestBanner: true,
                displayBeforeTestBanner: true,
				displayFenrirSVCBanner: true,
                frontImageData:"",
                imagePaths:[],
                pageStep: null,
                pageTitle: null
            }
        },

        mounted() {
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            }).then(() => {
                this.setCurrentStep({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    currentStep: this.$route.name,
                });
            });

            this.listImages();

            if (this.beforeOrAfter === "after") {
                this.pageStep = 8;
                this.pageTitle = "After";
            } else {
                this.pageStep = 2;
                this.pageTitle = "Before";
            }
        },

        computed: {
            ...mapGetters([
                "device",
                "isJobManager",
                "nextRepairStep",
                "prevRepairStep",
                "isRefurbOrMailIn"
            ]),

            /** Check if this is the before or after page */
            beforeOrAfter() {
                let page = this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1);

                if (page === 'after-pictures') {
                    return "after";
                }

                return "before";
            },

            /** Change the test image type based on the page */
            esdOrOqc() {
                let page = this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1);

                if (page === 'after-pictures') {
                    return "oqc";
                }

                return "esd";
            },

            /** Check if footer should be active */
            footerActive() {
                /** --TODO refactor on new release */
                return true;

                // let page = this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1);
                //
                // if (page === 'after-pictures') {
                //     if (!this.displayAfterTestBanner || this.isJobManager) {
                //         return true;
                //     }
                // }
                //
                // if (page === 'before-pictures') {
                //     if (!this.displayBeforeTestBanner || this.isJobManager) {
                //         return true;
                //     }
                // }
                //
                // return false;
            }
        },

        methods: {
            ...mapActions([
                "addDeviceImage",
                "displayToast",
                "loadDeviceForJob",
                "setCurrentStep"
            ]),

            compressAndAddPhoto(type, elementId) {
                const filePicker = document.getElementById(elementId);

                if (!filePicker || !filePicker.files || filePicker.files.length <= 0) {
                    this.displayToast({text: 'No file selected.', type: 'error'});
                    return;
                }

                let self = this;
                new Compressor(filePicker.files[0], {
                    quality: 0.6,
                    success(result) {

                        self.addPhoto(result, type, elementId);

                    },
                    error() {
                        self.displayToast({text: 'Could not parse file.', type: 'error'});
                        filePicker.value = "";
                    },
                });
            },

            /** Add a new photo */
            addPhoto(myFile, type, elementId) {
                return new Promise(async (resolve) => {

                    this.displayToast({text: 'File is being uploaded.', type: 'success'});

                    this.displayImage(myFile)
                        .then(result => {
                            this.imagePaths.push({
                                path:result,
                                type:type
                            });
                        });

                    let uuid = `${uuidv4()}|${type}`;

                    this.addDeviceImage({
                        jobId: this.jobId,
                        deviceId:this.deviceId,
                        type: type,
                        filename: uuid
                    });

                    const ref = firebase.storage().ref(`/images/${this.deviceId}/${uuid}`);

                    await ref.put(myFile);

                    this.listImages();

                    document.getElementById(elementId).value = "";

                    resolve();
                });
            },

            /** Display the image just taken using base64data */
            displayImage(myFile) {
                return new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    if (fileReader && myFile) {
                        fileReader.readAsDataURL(myFile);
                        fileReader.onload = () => {
                            resolve(fileReader.result);
                        };

                        fileReader.onerror = (error) => {
                            reject(error);
                        };
                    } else {
                        reject('No file provided');
                    }
                });
            },

            /** List images from firebase storage */
            listImages() {

                const listRef = firebase.storage().ref(`/images/${this.deviceId}`);

                let self = this;

                listRef.listAll().then(function(res) {

                    let urls = [];

                    res.items.forEach(function(itemRef) {

                        let splitPath = itemRef.fullPath.split("|");
                        let type = "other";

                        if (splitPath[1]) {
                            type = splitPath[1];
                        }

                        if (!itemRef.fullPath.includes(self.beforeOrAfter)) {
                            return;
                        }

                        urls.push({
                            path: `https://firebasestorage.googleapis.com/v0/b/${itemRef.bucket}/o/${encodeURIComponent(itemRef.fullPath)}?alt=media&token=${uuidv4()}`,
                            type: type
                        });
                    });

                    self.imagePaths = urls;

                }).then(() => {
                    this.imagePaths.forEach(image => {
						if (image.type === 'after-fenrir-svc') {
							this.displayFenrirSVCBanner = false;
						}

                        if (image.type === 'after-oqc-results') {
                            this.displayAfterTestBanner = false;
                        }

                        if (image.type === 'before-esd-results') {
                            this.displayBeforeTestBanner = false;
                        }
                    });
                }).catch(function(error) {
                    console.log(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>